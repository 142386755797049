import React, { ReactNode, useEffect, useContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Banner, Footer, Navbar } from 'component-library'
import { NavbarContent, FooterContent, BannerContent } from 'shared-content'
import LinkComponent from '../LinkComponent/LinkComponent'
import MarkdownText from '../MarkdownText/MarkdownText'
import { IEvent, AppContext } from '../../providers/AppContext/AppContext'
import 'boxicons/css/boxicons.min.css'

interface ILayoutProps {
  children?: ReactNode
}

const GET_EVENTS = gql(`
  query GetEvents {
    events {
      id
      slug
      status
      registrationOpen
      teamsLocked
    }
  }
`)

const Layout = ({ children }: ILayoutProps) => {
  const { setDayOneDetails, setDayTwoDetails } = useContext(AppContext)
  const { data } = useQuery(GET_EVENTS)

  useEffect(() => {
    if (data && data.events) {
      const dayOne = data.events.find(
        (event: IEvent) => event.slug === 'sqc-2024-1'
      )
      const dayTwo = data.events.find(
        (event: IEvent) => event.slug === 'sqc-2024-2'
      )

      if (dayOne) setDayOneDetails(dayOne)
      if (dayTwo) setDayTwoDetails(dayTwo)
    }
  }, [data])

  return (
    <>
      <Navbar
        leftItems={NavbarContent.navbarLeft}
        mobileButton={{
          text: '',
          link: '',
        }}
        LinkComponent={LinkComponent}
        rightItems={{
          buttonLink: {
            text: '',
            link: '',
          },
        }}
      />
      {BannerContent.bannerText.length > 0 && (
        <Banner bannerText={<MarkdownText text={BannerContent.bannerText} />} />
      )}
      <main>{children}</main>
      <Footer
        social={FooterContent.social}
        policies={FooterContent.policies}
        LinkComponent={LinkComponent}
      />
    </>
  )
}

export default Layout
