import React from 'react'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import Layout from './src/components/Layout/Layout'
import AppContextProvider from './src/providers/AppContext/AppContext'
import { getCurrentEnv } from './config'
import './src/scss/index.scss'

const client = new ApolloClient({
  uri: getCurrentEnv().graphqlUrl,
  cache: new InMemoryCache(),
})

const wrapPageElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <AppContextProvider>
        <Layout>{element}</Layout>
      </AppContextProvider>
    </ApolloProvider>
  )
}

// Export browser configurations
export { wrapPageElement }
