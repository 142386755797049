/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, SetStateAction } from 'react'
import { createContext, useState } from 'react'

export interface IEvent {
  id: string
  slug: string
  status: string
  registrationOpen: boolean | null
  teamsLocked: boolean | null
}

interface IAppContext {
  dayOneDetails: IEvent
  setDayOneDetails: (args: IEvent) => void
  dayTwoDetails: IEvent
  setDayTwoDetails: (args: IEvent) => void
}

export const AppContext = createContext<IAppContext>({
  dayOneDetails: {
    id: '',
    slug: '',
    status: '',
    registrationOpen: false,
    teamsLocked: true,
  },
  setDayOneDetails: (args: IEvent) => {},
  dayTwoDetails: {
    id: '',
    slug: '',
    status: '',
    registrationOpen: false,
    teamsLocked: true,
  },
  setDayTwoDetails: (args: IEvent) => {},
})

export const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [dayOneDetails, setDayOneDetails] = useState<IEvent>({
    id: '',
    slug: '',
    status: '',
    registrationOpen: false,
    teamsLocked: true,
  })

  const [dayTwoDetails, setDayTwoDetails] = useState<IEvent>({
    id: '',
    slug: '',
    status: '',
    registrationOpen: false,
    teamsLocked: true,
  })

  return (
    <AppContext.Provider
      value={{
        dayOneDetails,
        setDayOneDetails,
        dayTwoDetails,
        setDayTwoDetails,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const RootElement = ({ children }: { children: JSX.Element }) => {
  return <AppProvider>{children}</AppProvider>
}

export default RootElement
