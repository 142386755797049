import React, { LegacyRef, forwardRef } from 'react'
import ReactMarkdown, { Options } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { withPrefix } from 'gatsby'
import LinkComponent from '../LinkComponent/LinkComponent'
import { sendCustomGAEvent } from 'shared-utilities'

export interface IMarkdownTextProps {
  text: string
  className?: string
  testId?: string
  gaEventCategory?: string
  style?: React.CSSProperties
}

const MarkdownText = forwardRef(function MarkdownText(
  {
    text,
    className = '',
    testId = 'markdown-text',
    gaEventCategory,
    style,
  }: IMarkdownTextProps,
  ref: LegacyRef<HTMLDivElement> | undefined
) {
  return (
    <div className={`${className}`} data-testid={testId} ref={ref}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm] as Options['remarkPlugins']}
        components={{
          h1: 'h2',
          img: ({ ...props }) => (
            // eslint-disable-next-line react/prop-types
            <img {...props} src={withPrefix(String(props.src))} />
          ),
          p: ({ children }) => <p style={{ ...style }}>{children}</p>,
          a: ({ ...props }) => (
            <LinkComponent
              {...props}
              // eslint-disable-next-line react/prop-types
              href={String(props.href)}
              onClick={
                gaEventCategory
                  ? () => {
                      sendCustomGAEvent('click', {
                        event_category: gaEventCategory,
                        // eslint-disable-next-line react/prop-types
                        event_label: props.children[0],
                        // eslint-disable-next-line react/prop-types
                        value: props.href,
                      })
                    }
                  : undefined
              }
            />
          ),
        }}
      >
        {text}
      </ReactMarkdown>
    </div>
  )
})

export default MarkdownText
