const ENVIRONMENT_URLS = {
  LOCAL: {
    SITE_URL: 'http://localhost:3000',
    dashboardUrl: 'http://localhost:3001',
    graphqlUrl: 'https://dev.cloud.pennylane.ai/graphql',
  },
  DEV: {
    SITE_URL: 'https://dev.sgqcup.com',
    dashboardUrl: 'https://dev.dashboard.sgqcup.com',
    graphqlUrl: 'https://dev.cloud.pennylane.ai/graphql',
  },
  STAGING: {
    SITE_URL: 'https://staging.sgqcup.com',
    dashboardUrl: 'https://staging.dashboard.sgqcup.com',
    graphqlUrl: 'https://staging.cloud.pennylane.ai/graphql',
  },
  PROD: {
    SITE_URL: 'https://sgqcup.com',
    dashboardUrl: 'https://dashboard.sgqcup.com',
    graphqlUrl: 'https://cloud.pennylane.ai/graphql',
  },
}

export const getCurrentEnv = () => {
  const ORIGIN_URL = typeof window !== 'undefined' ? `${window.origin}` : ''
  const currentEnv =
    process.env.DEV ||
    (ORIGIN_URL.length > 0 &&
      ENVIRONMENT_URLS.DEV.SITE_URL.includes(ORIGIN_URL))
      ? ENVIRONMENT_URLS.DEV
      : process.env.STAGING ||
        (ORIGIN_URL.length > 0 &&
          ENVIRONMENT_URLS.STAGING.SITE_URL.includes(ORIGIN_URL))
      ? ENVIRONMENT_URLS.STAGING
      : process.env.PROD ||
        (ORIGIN_URL.length > 0 &&
          ENVIRONMENT_URLS.PROD.SITE_URL.includes(ORIGIN_URL))
      ? ENVIRONMENT_URLS.PROD
      : ENVIRONMENT_URLS.LOCAL
  return currentEnv
}
