export default {
  navbarLeft: [
    {
      name: 'About',
      href: '/#about',
    },
    {
      name: 'Schedule',
      href: '/schedule',
    },
//     {
//       name: 'Speakers',
//       href: '/speakers',
//     },
    // {
    //   name: 'Partners',
    //   href: '/partners',
    // },
    {
      name: 'Leaderboard',
      href: '/leaderboard',
    },
  ],
  navbarRight: {
    buttonLink: {
      // text: 'Challenge Portal',
      // link: 'https://dashboard.sgqcup.com',
      text: '',
      link: '',
    },
  },
  mobileButton: {
    // text: 'Challenge Portal',
    // link: 'https://dashboard.sgqcup.com',
    text: '',
    link: '',
  },
}
